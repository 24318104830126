import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Header from '../../components/Header';
import CMSView from '../../components/View';
import TemplateAdmin from '../../templates/admin';
import requireUser, { UserProps } from '../../utils/requireUser';

const PageAdminIndex: FC<PageProps & UserProps> = ({ user }) => (
  <TemplateAdmin>
    <CMSView>
      <Header title={`Bonjour ${user.firstName},`} />
      <p>Je te souhaite une bonne journée.</p>
    </CMSView>
  </TemplateAdmin>
);

export default requireUser(PageAdminIndex);
